import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import {
  type UniqueField,
  type AccountToFcp,
  type PreferredMatch
} from '../../../../types/fcp';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DataGrid, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import DropdownV2 from '../../DropdownV2/DropdownV2';
import Menu, { MenuItem } from 'rc-menu';
import { openToast } from '../../../../helpers/toast';
import IconButton from '../../IconButton';
import { formatOptions } from '../../../../helpers/fcp';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const uniqueFields: UniqueField[] = [
  'Unique Card ID',
  'Name On Card',
  'Unit Number',
  'Person ID',
  'Driver ID',
  'VIN',
  'Vehicle Number',
  'Card Pin'
];

const pairTypes: string[] = [
  'Vehicle',
  'Driver'
];

interface AccountToFcpProps {
  accountId: number;
}

const AccountToFcpSettingsModal: React.FC<AccountToFcpProps> = ({
  accountId
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [rows, setRows] = useState<AccountToFcp[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onHide = (): void => {
    setIsShown(false);
  };

  const getUniqueFuelCardProviders = async (): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts-unique-fcps/${accountId}`;
    try {
      setLoading(true);
      const response = await axios.get(url);
      if (response?.data?.data) {
        setRows(response.data.data.map((uniqueFcp: any, index: number): AccountToFcp => {
          return {
            id: index,
            fuelCardProvider: uniqueFcp.fcp_code,
            uniqueField: uniqueFcp.unique_card_id_field,
            preferredMatch: uniqueFcp.preferred_match
          };
        }));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isShown) {
      void getUniqueFuelCardProviders();
    }
  }, [isShown]);

  const handleUniqueFieldChange = async (
    fuelCardProvider: string,
    uniqueField: string,
    preferredMatch: string,
    index: number
  ): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts-to-unique-fcps`;
    const params = {
      account_id: accountId,
      fcp_code: fuelCardProvider,
      unique_card_id_field: uniqueField.toLowerCase().replaceAll(' ', '_'),
      preferred_match: preferredMatch.toLowerCase().replaceAll(' ', '_')
    };
    try {
      await axios.post(url, params);
    } catch (error: any) {
      console.log(error);
      openToast({
        type: 'error',
        label: 'Error updating account fuel card provider settings',
        autoClose: 2000,
        theme: 'dark'
      });
    }

    setRows(rows && [...rows].map((uniqueFcp: AccountToFcp): AccountToFcp => {
      if (uniqueFcp.id === index) {
        uniqueFcp.uniqueField = uniqueField;
        uniqueFcp.preferredMatch = preferredMatch as PreferredMatch;
      }
      return uniqueFcp;
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'fuelCardProvider',
      headerName: 'FCP',
      width: 150
    },
    {
      field: 'uniqueField',
      headerName: 'Unique Field',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <DropdownV2
            key={'dropdown-' + `${params.row.fuelCardProvider}` + `-${params.row.id}`}
            menu={(
              <Menu
                key={'menu-' + `${params.row.fuelCardProvider}` + `-${params.row.id}`}
                style={{ maxHeight: '300px', overflowY: 'auto' }}
              >
                {uniqueFields.map((field: string) => {
                  return (
                    <MenuItem
                      key={'menu-item-' + `${params.row.fuelCardProvider}` + '-' + field + '-' + `-${params.row.id}`}
                      className="cursor-pointer"
                      onClick={(): void => {
                        void handleUniqueFieldChange(params.row.fuelCardProvider, field, params.row.preferredMatch, params.row.id);
                      }}
                    >
                      {field}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          >
            <button
              type="button"
              className="flex items-center text-left gap-x-2 w-full"
            >
              <span className="truncate">{formatOptions(params.row.uniqueField)}</span>
              <Icon
                name="caret-down"
                key={'icon-' + `${params.row.fuelCardProvider}` + `-${params.row.id}`}
              />
            </button>
          </DropdownV2>
        );
      }
    },
    {
      field: 'preferredMatch',
      headerName: 'Pair Type',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <DropdownV2
            key={'dropdown-' + `${params.row.preferredMatch}` + `-${params.row.id}`}
            menu={(
              <Menu
                key={'menu-' + `${params.row.preferredMatch}` + `-${params.row.id}`}
                style={{ maxHeight: '300px', overflowY: 'auto' }}
              >
                {pairTypes.map((pairType: string) => {
                  return (
                    <MenuItem
                      key={'menu-item-' + `${params.row.preferredMatch}` + '-' + pairType + '-' + `-${params.row.id}`}
                      className="cursor-pointer"
                      onClick={(): void => {
                        void handleUniqueFieldChange(params.row.fuelCardProvider, params.row.uniqueField, pairType, params.row.id);
                      }}
                    >
                      {pairType}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          >
            <button
              type="button"
              className="flex items-center text-left gap-x-2 w-full"
            >
              <span className="truncate">{formatOptions(params.row.preferredMatch)}</span>
              <Icon
                name="caret-down"
                key={'icon-' + `${params.row.preferredMatch}` + `-${params.row.id}`}
              />
            </button>
          </DropdownV2>
        );
      }
    }
  ];

  return (
    <>
      <IconButton
        className="mt-5"
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="edit"/>
            <p>Edit FCP Settings</p>
          </div>
        )}
        onHide={onHide}
        show={isShown}
        customZIndex={1001}
        maxWidth={'40rem'}
      >
        {loading && (
          <div
            className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
          >
            <CircularProgress/>
          </div>
        )}
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            disableDensitySelector
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            rows={rows ?? []}
            columns={columns}
            density="compact"
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '20rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default AccountToFcpSettingsModal;
