import React, { useEffect, useState } from 'react';
import IconButton from '../../../ui/shared/IconButton';
import Modal from '../../../ui/shared/Modal';
import Icon from '../../../ui/shared/Icon';
import { Formik } from 'formik';
import Input from '../../../ui/shared/Formik/Input';
import Button from '../../../ui/shared/Button';
import { t } from 'i18next';
import axios, { AxiosError } from 'axios';
import { getParentAuthHeader } from '../../../helpers/api';
import { appState } from '../../..';
import { openToast } from '../../../helpers/toast';
import { CircularProgress } from '@mui/material';
import * as yup from 'yup';
import FuelCardSettingsTooltip from './FuelCardSettingsTooltip';
import Checkbox from '../../../ui/shared/Formik/Checkbox';

const defaultPairingsSchema = yup.object().shape({
  fcp_field: yup.string().required('Required'),
  geotab_field: yup.string().required('Required')
});

const FuelCardSettingsModal: React.FC = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [fcpField, setFcpField] = useState('');
  const [geotabField, setGeotabField] = useState('');
  const [wildcardSearch, setWildcardSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const getPairs = async (): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId.value}`;
    try {
      const response = await axios.get(url);
      console.log(response);
      setFcpField(response.data.data.fcp_field || '');
      setGeotabField(response.data.data.geotab_field || '');
      setWildcardSearch(response.data.data.wildcard_search || false);
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: t('Error getting default pairings'), autoClose: 2000, theme: 'dark' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (settingsSubmitted: any): Promise<boolean> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId}`;
      const params = {
        fcp_field: settingsSubmitted.fcp_field,
        geotab_field: settingsSubmitted.geotab_field,
        wildcard_search: settingsSubmitted.wildcard_search
      };
      const headers = getParentAuthHeader();
      await axios.put(
        url,
        params,
        headers ? { headers } : undefined
      );
      openToast({ type: 'success', label: t('Default Pairing Updated'), autoClose: 2000, theme: 'dark' });
      return true;
    } catch (error: AxiosError | any) {
      console.error('Error happened updating default pairing: ', error);
      let message = 'Error updating default pairing';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };

  useEffect(() => {
    if (isShown) {
      getPairs();
    }
  }, [isShown]);

  async function removeDefaultPairing (props: any): Promise<void> {
    const clearedDefaultPairs = {
      fcp_field: '',
      geotab_field: '',
      wildcard_search: false
    };
    props.setSubmitting(true);
    let hide = false;
    hide = await handleSubmit(clearedDefaultPairs);
    props.setSubmitting(false);
    if (hide) {
      setIsShown(false);
    }
  };

  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>{t('Fuel Card Settings')}</p>
          </div>
        )}
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        maxWidth={350}
      >
        {loading
          ? (
            <div className="loading-overlay">
              <CircularProgress />
            </div>
            )
          : (
            <Formik
              enableReinitialize
              validationSchema={defaultPairingsSchema}
              initialValues={{
                fcp_field: fcpField,
                geotab_field: geotabField,
                wildcard_search: wildcardSearch
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                let hide = false;
                hide = await handleSubmit(values);
                setSubmitting(false);
                if (hide) {
                  setIsShown(false);
                }
              }}
            >
              {(props: any) => (
                <form
                  onSubmit={props.handleSubmit}
                  className="mt-4"
                >
                  {/* Display loading spinner when isSubmitting is true */}
                  {props.isSubmitting && (
                    <div className="loading-overlay">
                      <CircularProgress />
                    </div>
                  )}
                  <div className="flex flex-col gap-y-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase font-bold">{t('Default Pairing')}</p>
                      <FuelCardSettingsTooltip />
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <Input
                        name="fcp_field"
                        type="select"
                        label={`${t('Select FCP Field')}`}
                        options={[
                          { key: t('VIN'), value: 'vin' },
                          { key: t('Card Pin'), value: 'card_pin' },
                          { key: t('Name On Card'), value: 'name_on_card' },
                          { key: t('Unit Card Id'), value: 'unique_card_id' },
                          { key: t('Driver ID'), value: 'driver_id' },
                          { key: t('Unit Number'), value: 'unit_number' },
                          { key: t('Vehicle Number'), value: 'vehicle_number' }
                        ]}
                        value={props.values.fcp_field}
                        onChange={props.handleChange}
                        defaultValue={fcpField}
                      />
                      <Input
                        name="geotab_field"
                        type="select"
                        label={`${t('Select Geotab Field')}`}
                        options={[
                          { key: t('VIN'), value: 'vehicleIdentificationNumber' },
                          { key: t('Vehicle Name'), value: 'name' },
                          { key: t('Comments'), value: 'comment' },
                          { key: t('License Plate'), value: 'licensePlate' },
                          { key: t('Serial Number'), value: 'serialNumber' },
                          { key: t('Employee Number'), value: 'employeeNo' },
                          { key: t('License Number'), value: 'licenseNumber' },
                          { key: t('Name'), value: 'name' },
                          { key: t('Phone Number'), value: 'phoneNumber' },
                          { key: t('Designation'), value: 'designation' }
                        ]}
                        value={props.values.geotab_field}
                        onChange={props.handleChange}
                        defaultValue={geotabField}
                      />
                      <Checkbox
                        name="wildcard_search"
                        label={(
                          <p style={{ paddingBottom: '6px' }}>{t('Wildcard Search')}</p>
                        )}
                        switchType
                      />
                    </div>
                    {(props.values.fcp_field !== '' || props.values.geotab_field !== '') && (
                      <Button
                        disabled={props.isSubmitting}
                        style={{ margin: '15px 0px -10px' }}
                        type="button"
                        onClick={() => { removeDefaultPairing(props); }}
                      >
                        {t('Remove Default Pairing')}
                      </Button>
                    )}
                    <Button
                      disabled={props.isSubmitting}
                      className="!px-12"
                    >
                      {t('Submit')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
            )}
      </Modal>
    </>
  );
};

export default FuelCardSettingsModal;
